import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'animate.css'
// import animated from 'animate.css'
// 滚动动画 wow.js
import { WOW } from 'wowjs'

import { Header, Container, Main, Tooltip, Button } from 'element-ui'
import FooterView from './components/FooterView/index.vue'
Vue.component('FooterView', FooterView)
Vue.config.productionTip = false
// Vue.use(animated)
Vue.prototype.$wow = new WOW({
  boxClass: 'wow', // default
  animateClass: 'animated', // default
  offset: 50, // default
  mobile: true, // default
  live: false,

  // live为true时，控制台会提示：MutationObserver is not supported by your browser. & WOW.js cannot detect dom mutations, please call .sync() after loading new content.
  callback: function (box) {
    console.log('WOW: animating <' + box.tagName.toLowerCase() + '>')
  }
})
Vue.use(Header)
Vue.use(Container)
Vue.use(Main)
Vue.use(Tooltip)
Vue.use(Button)
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
