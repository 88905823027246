<template>
  <div class="footer">
    <img src="../../assets//首页//cllogo2.png" />
    <!-- 联系 -->
    <div class="contact">
      <div class="row">
        <ul>
          <li>
            <a href="/"></a>
            <router-link to="/">首页</router-link>
          </li>
          <li>
            <router-link to="/productCenter">产品中心</router-link>
          </li>
          <li>
            <router-link to="/solution">解决方案</router-link>
          </li>
          <li style="border: 0">
            <router-link to="/about">关于我们</router-link>
          </li>
        </ul>
      </div>
      <div class="row">
        联系电话：18019680808 邮箱：chuilian@chuilian·tech.cn
        网址：www.chuilian-tech.cn/
      </div>
      <div class="row">
        地址：江苏省常州市武进区中国以色列常州创新园17号楼309、315
      </div>
      <div class="row">
        <a href="https://beian.miit.gov.cn/" style="color: #fff"
          >ICP备案：苏ICP备2022010236号</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterView'
}
</script>

<style scoped lang="scss">
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 9.375vw;
  background-color: #000;
  position: relative;
  img {
    top: 50%;
    transform: translateY(-50%);
    left: 14.375vw;
    position: absolute;
    height: 2.4479vw;
  }
}
.footer .contact {
  color: #fff;
  text-align: center;
  .row {
    margin-bottom: 0.5208vw;
    font-size: 0.8333vw;
  }
  ul {
    display: flex;
    justify-content: center;
    list-style: none;
    li {
      padding: 0 1.0417vw;
      border-right: 1px solid #fff;
      a {
        color: #fff;
      }
    }
  }
}
</style>
