<template>
  <div id="app">
    <!-- 头部 -->
    <header style="height: 5.2083vw" class="header">
      <div class="logo">
        <h1 style="display: none">锤链科技</h1>
        <img src="./assets//首页//cllogo1.png" class="logoimg" />
      </div>
      <!-- 导航 -->
      <div class="nav_box">
        <nav class="nav">
          <router-link to="/" :exact="isExact('/')">首页</router-link>
          <el-tooltip placement="top">
            <div slot="content">
              <div class="productLink">
                <el-button
                  @click="handleButtonClick('/productCenter/product')"
                  :class="productBtnClass"
                  >智能生产管理系统</el-button
                >
                <el-button
                  @click="handleButtonClick('/productCenter/device')"
                  :class="productBtnClass"
                  >智慧设备管理平台</el-button
                >
                <el-button
                  @click="handleButtonClick('/productCenter/ai')"
                  :class="productBtnClass"
                  >AI获客邮件精灵</el-button
                >
              </div>
            </div>
            <router-link to="/productCenter" :class="productBtnClass"
              >产品中心</router-link
            >
          </el-tooltip>
          <router-link to="/solution" :exact="isExact('/solution')"
            >解决方案</router-link
          >
          <router-link to="/about" :exact="isExact('/about')"
            >关于我们</router-link
          >
        </nav>
      </div>
    </header>
    <!-- 主体 -->
    <div class="body">
      <router-view />
    </div>
    <!-- 回到顶部 -->
    <div class="gotop-wrapper" v-show="showBackToTop">
      <a href="javascript:;" class="fixed-gotop gotop" @click="scrollToTop"></a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      scrollY: 0, // 当前滚动距离
      showBackToTop: false, // 是否显示返回顶部按钮
      threshold: 300 // px 滚动距离阈值
    }
  },
  computed: {
    productBtnClass() {
      return this.$route.path.startsWith('/productCenter') ? 'active' : ''
    },
    // 计算属性，返回当前是否应该显示返回顶部按钮
    shouldShowBackToTop() {
      return this.scrollY >= this.threshold // 假设滚动距离超过 500px 时显示按钮
    }
  },
  watch: {
    // 监听 scrollY 属性的变化，更新 showBackToTop 属性
    scrollY(newValue) {
      this.showBackToTop = this.shouldShowBackToTop
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    isExact(routePath) {
      return this.$route.path === routePath
    },
    handleButtonClick(path) {
      if (this.$route.path !== path) {
        this.$router.push(path)
      }
    },
    handleScroll() {
      this.scrollY = window.scrollY
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
<style lang="scss">
@import './css/index.css';

$primary-color: #c21920;
* {
  margin: 0;
  padding: 0;
  user-select: none;
}
a {
  list-style: none;
  text-decoration: none;
}
.header {
  position: relative;
}
/* logo图 */
.logo {
  position: absolute;
  left: 15.625vw;
  top: 1.0417vw;
  width: 9.7917vw;
}
.logoimg {
  width: 100%;
}
/* 导航 */
.nav_box {
  position: fixed;
}
.nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  left: 41.6667vw;
  width: 44.7917vw;
  height: 5.2083vw;
  background-color: #fff;
  border-radius: 0 0 0.2604vw 0.2604vw;
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.2);
  .active {
    background-color: $primary-color;
    color: #fff;
  }
}
/* 四个导航按钮 */
.nav a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
  height: 100%;
  color: black;
  font-size: 0.8333vw;
}
.nav :hover,
.nav :active,
.nav .router-link-exact-active {
  background-color: $primary-color;
  color: #fff;
}

/* 气泡 */
.el-tooltip__popper,
.is-dark {
  width: 26.0417vw;
  height: 1vw;
}
/* 产品中心的三个链接 */
.productLink {
  font-size: 0.625vw;
  display: flex;
  justify-content: space-evenly;
}
.el-tooltip__popper {
  border-radius: 0.6604vw;
}
.el-tooltip__popper.is-dark {
  background-color: #fff;
}
.productLink .el-button {
  flex: 1;
  padding: 0;
  border: 1px solid #fff;
  color: black;
  font-size: 0.8333vw;
}
/* 小三角 */
.el-tooltip__popper[x-placement^='bottom'] .popper__arrow {
  border-bottom-color: #fff;
}
.el-tooltip__popper[x-placement^='bottom'] .popper__arrow::after {
  border-bottom-color: #fff;
}
/* 主体 */
.body {
  position: absolute;
  top: 0;
  z-index: -1;
}
/**回到顶部 */
.gotop-wrapper {
  position: fixed;
  bottom: 50px;
  right: 15px;
  z-index: 1001;
  // width: 55px;
  // height: 55px;
  background-color: rgba(0, 0, 0, 0.3);
}
.gotop-wrapper a {
  display: block;
  width: 55px;
  height: 55px;
  overflow: hidden;
  background: url('./assets/icon-gotop-fixed.gif') no-repeat center;
}
</style>
